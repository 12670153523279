<!--
 * @Description: 
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-13 21:47:04
 * @FilePath: /JinChanTuoKe-Front/src/pages/basic/nav.vue
-->
<template>
  <div class="nav">
    <div class="nav-view">
      <div v-if="list.length === 1" class="nav-item nav1">
        <span>{{ list[0] }}</span>
      </div>
      <template v-else-if="list.length === 2">
        <div class="nav-item nav2">
          <span>{{ list[0] }}</span>
        </div>
        <div class="nav-item nav3">
          <span>{{ list[1] }}</span>
        </div>
      </template>
      <template v-else-if="list.length === 3">
        <div class="nav-item nav2">
          <span>{{ list[0] }}</span>
        </div>
        <div class="nav-item nav4">
          <span>{{ list[1] }}</span>
        </div>
        <div class="nav-item nav3">
          <span>{{ list[2] }}</span>
        </div>
      </template>
      <template v-if="$route.path === '/common'">
        <div class="nav-item nav2">
          <span>工作台</span>
        </div>
        <div class="nav-item nav3">
          <span>常见问题</span>
        </div>
      </template>
      <template v-else-if="$route.path === '/collect'">
        <div class="nav-item nav2">
          <span>线索管理</span>
        </div>
        <div class="nav-item nav4">
          <span>线索&互动</span>
        </div>
        <div class="nav-item nav3">
          <span>我的收藏</span>
        </div>
      </template>
    </div>
    <div class="nav-opts">
      <el-dropdown v-if="info" @command="handleCommand">
        <div class="opt-item">
          {{ info.name || info.nickname }} <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="login">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { getCookie, setCookie, setToken } from "../../utils/common";
import { HTTP } from "../../utils/request";

const URL = {
  info: "api/customer/user/info",
  operationInfo: "/api/operation/user/info",
};

export default {
  data() {
    return {
      list: [],
      clientList: getCookie("menu_list"),
      info: null,
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(v) {
        this.handleNavList(v?.substring(1));
      },
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const role = getCookie("role");
      const res = await HTTP({
        url: URL[role === "operation" ? "operationInfo" : "info"],
        method: "get",
      });
      this.info = res;
    },
    handleNavList(name) {
      let list = [];
      this.clientList.forEach((e) => {
        if (e?.childList?.length) {
          e.childList.forEach((v) => {
            if (v.index === name) {
              list = [e.name, v.name];
            } else if (v?.childList?.length) {
              v.childList.forEach((c) => {
                if (c.index === name) {
                  list = [e.name, v.name, c.name];
                }
              });
            }
          });
        } else {
          if (e.index === name) {
            list = [e.name];
          }
        }
      });
      this.list = list;
    },
    // 注销
    handleCommand(type) {
      if (type === "login") {
        setToken(null);
        setCookie("menu_list", []);
        this.$router.push({
          name: "Login",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-view {
  display: flex;
  margin-left: 26px;
  margin-top: 16px;
  .nav-item {
    width: 111px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #232323;
    span {
      // padding-left: 25px;
      text-align: center;
    }
  }
  .nav1 {
    background-image: url("../../assets/menu/nav1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    color: #fff;
  }
  .nav2 {
    background-image: url("../../assets/menu/nav2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
  }
  .nav3 {
    background-image: url("../../assets/menu/nav3.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    color: #fff;
  }
  .nav4 {
    background-image: url("../../assets/menu/nav4.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
  }
}
.nav-opts {
  display: flex;
  align-items: center;
  margin-right: 20px;
  .opt-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
