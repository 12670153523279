<!--
 * @Description: 菜单
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-13 20:32:24
 * @FilePath: /JinChanTuoKe-Front/src/pages/basic/menu.vue
-->
<template>
  <div class="menu-list">
    <el-menu
      class="el-menu-vertical-demo"
      :default-active="menuActive"
      @select="handleSelect"
    >
      <div v-for="item in menuList" :key="item.index">
        <el-submenu v-if="item.childList" :index="item.index">
          <template slot="title">
            <div class="menu-submenu">
              <div class="menu-icon">
                <img
                  :src="
                    require(`../../assets/menu/${
                      menuActive === item.index ? item.activeIcon : item.icon
                    }.png`)
                  "
                  alt=""
                />
              </div>
              <div class="menu-title">{{ item.name }}</div>
            </div>
          </template>
          <div v-for="cell in item.childList" :key="cell.index">
            <router-link :to="`/${cell.index}`">
              <el-menu-item :index="cell.index">
                <template slot="title">
                  <div class="menu-submenu-item">
                    <div class="menu-item-title">{{ cell.name }}</div>
                  </div>
                </template>
              </el-menu-item>
            </router-link>
          </div>
        </el-submenu>
        <div v-else>
          <router-link :to="`/${item.index}`">
            <el-menu-item :index="item.index">
              <template slot="title">
                <div class="menu-submenu">
                  <div class="menu-icon">
                    <img
                      :src="
                        require(`../../assets/menu/${
                          menuActive === item.index
                            ? item.activeIcon
                            : item.icon
                        }.png`)
                      "
                      alt=""
                    />
                  </div>
                  <div class="menu-title">{{ item.name }}</div>
                </div>
              </template>
            </el-menu-item>
          </router-link>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { getCookie } from "../../utils/common";
export default {
  data() {
    return {
      menuActive: "",
      menuList: [],
      menu: getCookie("menu_list"),
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler() {
        this.menuActive = this.$route.path
          ? this.$route.path.substring(1)
          : this.menu[0]?.childList
          ? this.menu[0]?.childList[0]?.index
          : this.menu[0].index;
      },
    },
  },

  mounted() {
    this.menuList = this.menu;
    this.menuActive = this.$route.path
      ? this.$route.path.substring(1)
      : this.menu[0]?.childList
      ? this.menu[0]?.childList[0]?.index
      : this.menu[0].index;
  },
  methods: {
    handleSelect(key) {
      this.menuActive = key;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-list {
  height: calc(100vh - 71px);
  overflow-y: auto;
}
::v-deep .el-submenu__title {
  height: 38px;
  line-height: 38px;
  margin-top: 15px;
  color: #7b7f97 !important;
}
::v-deep .el-menu-item {
  height: 38px;
  line-height: 38px;
  margin-top: 13px;
  color: #7b7f97 !important;
}
::v-deep .el-menu {
  border: 0;
}
.el-menu-vertical-demo {
  ::v-deep {
    .is-active {
      color: #4d6bef !important;
    }
  }
}

.menu-submenu {
  display: flex;
  align-items: center;
}
.menu-icon {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  img {
    width: 15px;
    display: block;
  }
}
.menu-title {
  font-size: 15px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  //   color: #7b7f97;
  //   margin-left: 5px;
}
.menu-submenu-item {
  .menu-item-title {
    font-size: 15px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    margin-left: 9px;
  }
}
</style>
