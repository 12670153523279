<!--
 * @Description: 导航
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-13 20:33:10
 * @FilePath: /JinChanTuoKe-Front/src/pages/basic/index.vue
-->
<template>
  <div class="basic-view">
    <div class="basic-left">
      <div class="basic-logo">金蝉拓客</div>
      <MenuView />
    </div>
    <div class="basic-content">
      <NavView />
      <div class="basic-router">
        <router-view />
      </div>
      <Filings />
    </div>
  </div>
</template>

<script>
import MenuView from "./menu";
import NavView from "./nav";
export default {
  components: {
    MenuView,
    NavView,
  },
};
</script>

<style lang="scss" scoped>
.basic-view {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  .basic-left {
    width: 163px;
    height: 100vh;
    background-color: #fff;
    overflow: hidden;
    .basic-logo {
      width: 100%;
      font-size: 31px;
      font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
      font-weight: bold;
      color: #4d6bef;
      line-height: 41px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 10px;
      overflow: hidden;
    }
  }
  .basic-content {
    width: calc(100% - 163px);
    .basic-router {
      width: calc(100% - 32px);
      margin-left: 16px;
      margin-top: 16px;
      height: calc(100% - 85px - 35px);
      overflow-y: auto;
    }
  }
}
</style>
