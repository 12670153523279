var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-list"},[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":_vm.menuActive},on:{"select":_vm.handleSelect}},_vm._l((_vm.menuList),function(item){return _c('div',{key:item.index},[(item.childList)?_c('el-submenu',{attrs:{"index":item.index}},[_c('template',{slot:"title"},[_c('div',{staticClass:"menu-submenu"},[_c('div',{staticClass:"menu-icon"},[_c('img',{attrs:{"src":require(`../../assets/menu/${
                    _vm.menuActive === item.index ? item.activeIcon : item.icon
                  }.png`),"alt":""}})]),_c('div',{staticClass:"menu-title"},[_vm._v(_vm._s(item.name))])])]),_vm._l((item.childList),function(cell){return _c('div',{key:cell.index},[_c('router-link',{attrs:{"to":`/${cell.index}`}},[_c('el-menu-item',{attrs:{"index":cell.index}},[_c('template',{slot:"title"},[_c('div',{staticClass:"menu-submenu-item"},[_c('div',{staticClass:"menu-item-title"},[_vm._v(_vm._s(cell.name))])])])],2)],1)],1)})],2):_c('div',[_c('router-link',{attrs:{"to":`/${item.index}`}},[_c('el-menu-item',{attrs:{"index":item.index}},[_c('template',{slot:"title"},[_c('div',{staticClass:"menu-submenu"},[_c('div',{staticClass:"menu-icon"},[_c('img',{attrs:{"src":require(`../../assets/menu/${
                        _vm.menuActive === item.index
                          ? item.activeIcon
                          : item.icon
                      }.png`),"alt":""}})]),_c('div',{staticClass:"menu-title"},[_vm._v(_vm._s(item.name))])])])],2)],1)],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }